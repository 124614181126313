import { types } from 'mobx-state-tree';

const MCertificate = types.model({
  serialNumber: types.string,
  downloadUrl: types.string,
});

const MCertificateDetails = types.model({
  id: types.string,
  name: types.maybeNull(types.string),
  date: types.string,
  isNew: types.boolean,
  thumbnail: types.string,
});

export { MCertificate, MCertificateDetails };
