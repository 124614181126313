/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["self"] }] */
import { myTeamApi } from 'api';
import { isTalentRocksApp } from 'env';
import { flow, types } from 'mobx-state-tree';
import { TActivityAttendeesCountResponse, TActivityResponse } from 'types/myTeamApiTypes';
import { ActivityRange } from '_constants';
import MActivity from './MActivity';
import MActivityAttendeesCount from './MActivityAttendeesCount';
import MGroupActivityAttendees from './MActivityAttendees';

const MActivityAttendees = types
  .model({
    key: types.identifier,
    count: types.maybeNull(MActivityAttendeesCount),
    active: types.optional(MGroupActivityAttendees, {}),
    inactive: types.optional(MGroupActivityAttendees, {}),
  })
  .actions((self) => ({
    fetchActiveAttendees: flow(function* fetchActiveAttendees(groupId?: number) {
      yield self.active.fetchAttendees(self.key as ActivityRange, true, groupId);
    }),
    fetchInactiveAttendees: flow(function* fetchInactiveAttendees(groupId?: number) {
      yield self.inactive.fetchAttendees(self.key as ActivityRange, false, groupId);
    }),
    fetchAttendeesCount: flow(function* fetchGroupActivityAttendeesCount(groupId?: number) {
      const getGroupActivityAttendeesCount = isTalentRocksApp
        ? myTeamApi.groupApi.getGroupActivityAttendeesCount
        : myTeamApi.groupApi.getMobileGroupActivityAttendeesCount;
      const getAllActivityAttendeesCount = isTalentRocksApp
        ? myTeamApi.getAllActivityAttendeesCount
        : myTeamApi.getMobileAllActivityAttendeesCount;
      const { data, hasError }: TActivityAttendeesCountResponse = groupId
        ? yield getGroupActivityAttendeesCount(groupId, self.key as ActivityRange)
        : yield getAllActivityAttendeesCount(self.key as ActivityRange);
      if (!!data && !hasError) {
        self.count = data;
      }
      return !data;
    }),
  }));

const MActivityInfo = types
  .model({
    activityCounter: types.maybeNull(MActivity),
    attendees: types.map(MActivityAttendees),
  })
  .actions((self) => ({
    createAttendeesGroup: (key: string) => {
      if (!self.attendees.get(key)) {
        self.attendees.put({
          key,
          inactive: MGroupActivityAttendees.create(),
          active: MGroupActivityAttendees.create(),
        });
      }
    },
    fetchActivity: flow(function* fetchGroupActivity(groupId?: number) {
      const getGroupActivity = isTalentRocksApp
        ? myTeamApi.groupApi.getGroupActivity
        : myTeamApi.groupApi.getMobileGroupActivity;

      const getAllActivity = isTalentRocksApp ? myTeamApi.getAllActivity : myTeamApi.getMobileAllActivity;

      const { data, hasError }: TActivityResponse = groupId ? yield getGroupActivity(groupId) : yield getAllActivity();

      if (!!data && !hasError) {
        self.activityCounter = data;
      }
      return data;
    }),
  }));

export default MActivityInfo;
