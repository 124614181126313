import { ActionSheet } from 'antd-mobile';
import { IntlShape } from 'react-intl';
import { SDK } from 'utils';
import './ActionSheet.css';
import messages from './messages';

const isIPhone = new RegExp('\\biPhone\\b|\\biPod\\b', 'i').test(window.navigator.userAgent);
// eslint-disable-next-line @typescript-eslint/no-explicit-any
let wrapProps: any;
if (isIPhone) {
  wrapProps = {
    onTouchStart: (e: React.SyntheticEvent) => e.preventDefault(),
  };
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const showActionSheet = (buttons: string[], callbacks: Array<() => void>, intl: IntlShape, onClose?: () => void) => {
  const BUTTONS = [...buttons, intl.formatMessage(messages.cancelButton)];
  ActionSheet.showActionSheetWithOptions(
    {
      options: BUTTONS,
      cancelButtonIndex: BUTTONS.length - 1,
      maskClosable: true,
      // @ts-expect-error Object literal may only specify known properties, and 'wrapProps' does not exist in type 'ActionSheetWithOptions'
      wrapProps,
    },
    (buttonIndex: number) => {
      [...callbacks, onClose][buttonIndex]?.();
    },
  );
  SDK.setLeftButtonCallback(() => {
    ActionSheet.close();
    onClose?.();
  });
};

export default showActionSheet;
