import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import { useIntl } from 'react-intl';
import { TLearningTrackInfo, TDashboardTrackInfo } from 'types';
import { LearningTracksStatuses } from 'models/LearningTracks/constants';
import { DashboardCourseTrackStatuses } from 'models';
import { CardLabel, Image } from 'components';
import { ReactComponent as LearningTrackIcon } from 'assets/icons/learning-track.svg';
import { ParticipationDeadlineStatus, ProgressBarWidth } from '_constants';
import store from 'store';
import { LabelType } from 'components/CardLabel';
import { convertISOToDayMonth } from 'utils';
import {
  Body,
  Wrapper,
  StyledProgress,
  Info,
  Percent,
  Title,
  Header,
  StagesWrapper,
  StyledCheckmarkIcon,
  CompletedLabel,
  CourseCardImageOverlay,
  StyledTrackIcon,
  HeaderLeft,
  LabelsLine,
} from './TrackCard.styles';
import messages from './messages';

interface ILearningTrackCard {
  isNew: boolean;
  isInDashboard?: boolean;
}

const TrackCard: React.FC<(ILearningTrackCard & TLearningTrackInfo) | (ILearningTrackCard & TDashboardTrackInfo)> = ({
  name,
  totalSteps,
  completedSteps,
  status,
  viewInAdminMode,
  coverThumbnailUrl,
  coverUrl,
  isNew,
  isInDashboard,
  deadlineDate,
  participationDeadlineStatus: deadlineStatus,
}) => {
  const intl = useIntl();
  const percent = totalSteps && completedSteps && Math.round((completedSteps / totalSteps) * 100);
  const cover = useMemo(() => coverThumbnailUrl || coverUrl, [coverUrl, coverThumbnailUrl]);
  return (
    <Wrapper isInDashboard={isInDashboard}>
      {cover && (
        <CourseCardImageOverlay>
          <Image original={cover} thumbnail={coverThumbnailUrl} />
        </CourseCardImageOverlay>
      )}
      <Header>
        <HeaderLeft>
          {deadlineDate &&
            status !== LearningTracksStatuses.COMPLETED &&
            status !== DashboardCourseTrackStatuses.COMPLETED && (
              <LabelsLine>
                {deadlineStatus === ParticipationDeadlineStatus.HAS_BEEN_REACHED && (
                  <CardLabel labelType={LabelType.EXPIRED} />
                )}
                <CardLabel labelType={LabelType.DEADLINE} additionalText={convertISOToDayMonth(deadlineDate)} />
              </LabelsLine>
            )}
          {viewInAdminMode && <CardLabel labelType={LabelType.ADMIN} />}
        </HeaderLeft>
        {isNew && <CardLabel labelType={LabelType.NEW} />}
      </Header>
      <Body>
        <StyledTrackIcon>
          <LearningTrackIcon />
        </StyledTrackIcon>
        <Title ellipsis={{ rows: 3 }}>{name}</Title>
        <Info
          isCompleted={status === LearningTracksStatuses.COMPLETED || status === DashboardCourseTrackStatuses.COMPLETED}
        >
          <StagesWrapper>
            {(status === LearningTracksStatuses.NOT_STARTED || status === DashboardCourseTrackStatuses.NOT_STARTED) &&
              intl.formatMessage(messages.stagesCount, { count: totalSteps })}
            {(status === LearningTracksStatuses.COMPLETED || status === DashboardCourseTrackStatuses.COMPLETED) && (
              <>
                <StyledCheckmarkIcon />
                <CompletedLabel>{intl.formatMessage(messages.completed)}</CompletedLabel>
              </>
            )}
            {(status === LearningTracksStatuses.IN_PROGRESS || status === DashboardCourseTrackStatuses.IN_PROGRESS) &&
              intl.formatMessage(messages.completedOf, { stagesPassed: completedSteps, stagesCount: totalSteps })}
          </StagesWrapper>
          <Percent
            isSuccess={status === LearningTracksStatuses.COMPLETED || status === DashboardCourseTrackStatuses.COMPLETED}
          >
            {percent || 0}%
          </Percent>
        </Info>
        {status !== LearningTracksStatuses.COMPLETED && status !== DashboardCourseTrackStatuses.COMPLETED && (
          <StyledProgress
            percent={Math.max(percent, ProgressBarWidth.MD)}
            showInfo={false}
            strokeWidth={4}
            trailColor={store.UIStore.theme.palette.white.primary}
            strokeColor={store.UIStore.theme.palette.success.primary}
          />
        )}
      </Body>
    </Wrapper>
  );
};
export default observer(TrackCard);
