/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["self"] }] */
/* eslint no-restricted-syntax: ["off"] */
import { types, flow, cast } from 'mobx-state-tree';
import { MCertificateDetails, MCertificate } from 'models';
import { TCertificatesDetailsResponse, TCertificateResponse } from 'types/certificatesTypes';
import { TCertificate } from 'types';
import { certificatesApi } from 'api';

const Certificates = types
  .model({
    certificateDetails: types.optional(types.array(MCertificateDetails), []),
    certificates: types.optional(types.map(MCertificate), {}),
    loading: types.optional(types.boolean, false),
  })
  .actions((self) => ({
    setLoading(loading: boolean) {
      self.loading = loading;
    },
    getCertificateById(id: string) {
      return self.certificates.get(id);
    },
    setCertificateById(id: string, data: TCertificate) {
      self.certificates.set(id, cast(data));
    },
  }))
  .actions((self) => ({
    fetchCertificates: flow(function* fetchCertificates() {
      const { data, hasError }: TCertificatesDetailsResponse = yield certificatesApi.getCertificates();
      if (!hasError && data) {
        self.certificateDetails = cast(data.items);
      }
    }),
    fetchCertificatesByIds: flow(function* fetchCertificatesByIds() {
      for (const certificate of self.certificateDetails) {
        const { data, hasError }: TCertificateResponse = yield certificatesApi.getCertificate(certificate.id);

        if (!hasError && data) {
          self.certificates.set(certificate.id, cast(data));
        }
      }
    }),
  }));

export default Certificates;
