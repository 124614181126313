import { envApiUrls } from 'eventicious-env';
import { Locales, currentLocale } from '_constants/Locales';

const talentRocksLandingConfig: { [key: string]: string } = {
  'test-yandex': currentLocale === Locales.RU ? 'https://test.talentrocks.ru' : 'https://test.talentrocks.io',
  'test-yandex-2': currentLocale === Locales.RU ? 'https://test.talentrocks.ru' : 'https://test.talentrocks.io',
  'prod-yandex': currentLocale === Locales.RU ? 'https://talentrocks.ru' : 'https://talentrocks.io',
};

let APP_ENV = 'none';

if (process.env.REACT_APP_ENV) {
  APP_ENV = process.env.REACT_APP_ENV;
}

const baseUrlParam = new URLSearchParams(window.location.search).get('baseUrl');

const getEnv = (env = APP_ENV) => envApiUrls[env];

const getApiHost = (): string => getEnv().apiHost;
const getMobileApiHost = (): string => baseUrlParam || getEnv().mobileApiHost;
const getCdnUrl = (): string => getEnv().cdnUrls[0];
const getStreamsApiHost = (): string => getEnv().streamsApiHost;
const getMediawallApiHost = (): string => getEnv().mediaWallApiHost;
const getLandingUrl = (): string => talentRocksLandingConfig[APP_ENV];
const isTestEnv = (): boolean => getEnv().isTestEnv;

const isTalentRocksApp = process.env.REACT_APP_ENTRY === undefined;
const isGamificationApp = process.env.REACT_APP_ENTRY === 'Gamification';
const isMyTeamApp = process.env.REACT_APP_ENTRY === 'MyTeam';

export {
  getApiHost,
  getMobileApiHost,
  getCdnUrl,
  getStreamsApiHost,
  getMediawallApiHost,
  getLandingUrl,
  isTestEnv,
  isTalentRocksApp,
  isGamificationApp,
  isMyTeamApp,
};
