import styled, { css } from 'styled-components';
import { Avatar, Steps, Badge } from 'antd';
import { Grid } from '_constants';

import Eye from 'assets/icons/eye-reverse.svg';
import TailPath from 'assets/icons/tail-path.svg';
import Tooltip from 'components/Tooltip';

export const StyledSteps = styled(Steps)`
  &&& {
    margin-top: 40px;
    .ant-steps-item {
      padding-bottom: 28px;
      &:not(:last-child) {
        padding-bottom: 72px;

        @media (max-width: ${Grid.MD}px) {
          padding-bottom: 20px;
        }
      }

      .ant-steps-item-tail {
        @media (max-width: ${Grid.MD}px) {
          left: 0;
          top: 0;
          position: relative;
          order: 2;
          height: 72px;
          margin-top: 32px;
        }
      }

      &:nth-child(odd) {
        .ant-steps-item-container {
          @media (max-width: ${Grid.MD}px) {
            align-items: center;
          }
        }

        .ant-steps-item-tail {
          &::after {
            top: 54px;
            right: 149px;

            @media (max-width: ${Grid.MD}px) {
              top: auto;
              right: 0;
              bottom: 0;
              left: 0;
              margin: auto;
            }
          }
        }
      }

      &:nth-child(even) {
        .ant-steps-item-container {
          align-items: flex-end;

          @media (max-width: ${Grid.MD}px) {
            align-items: center;
          }
        }

        .ant-steps-item-tail {
          &::after {
            top: 54px;
            left: 118px;
            transform: scaleX(-1);

            @media (max-width: ${Grid.MD}px) {
              top: auto;
              right: 0;
              bottom: 0;
              left: 0;
              margin: auto;
              transform: none;
            }
          }
        }
      }
    }

    .ant-steps-item-container {
      display: flex;
      flex-direction: column;
      position: relative;
    }

    .ant-steps-item-container > .ant-steps-item-icon {
      display: none;
    }

    .ant-steps-item-tail {
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;

      width: 100%;
      padding: 0;
      top: 0;
      right: 0;

      &::after {
        content: '';
        display: block;
        width: 444px;
        height: 192px;
        background-color: ${({ theme }) => theme.palette.dark.dark_16};
        mask: url(${TailPath}) 100%;
        position: absolute;
        transition: background-color 0.2s;

        @media (max-width: ${Grid.MD}px) {
          width: 5px;
          height: 72px;
          mask-repeat: no-repeat;
        }
      }
    }

    .ant-steps-item-content {
      width: 100%;
      max-width: 272px;
      position: relative;
      z-index: 10;
    }

    .ant-steps-item-title {
      margin-top: -4px;
      font-family: 'Roboto', sans-serif;
      color: ${({ theme }) => theme.palette.dark.dark_40};
      transition: color 0.2s;
    }

    .ant-badge {
      width: 120px;
      height: 120px;
      margin-bottom: 8px;
    }

    .ant-badge .ant-progress-circle .ant-progress-inner {
      background-color: ${({ theme }) => theme.palette.white.primary};
    }

    .ant-steps-item-description {
      padding-bottom: 0;
      min-height: 209px;

      @media (max-width: ${Grid.MD}px) {
        min-height: auto;
      }
    }

    .ant-steps-item-description > div > span {
      padding: 0;
      max-width: 272px;
    }

    .ant-steps-item-finish {
      .ant-steps-item-tail {
        &::after {
          background-color: ${({ theme }) => theme.palette.success.primary};
        }
      }
      .ant-steps-item-title {
        color: ${({ theme }) => theme.palette.success.primary};
      }
    }

    .ant-steps-item-finish,
    .ant-steps-item-process,
    .ant-steps-item-active {
      .ant-steps-item-description > div > span:nth-child(2) {
        color: ${({ theme }) => theme.palette.dark.main};
      }
    }
  }

  .ant-steps-item:not(:last-child) > .ant-steps-item-container > .ant-steps-item-tail {
    display: flex;
  }

  .ant-steps-item:last-child > .ant-steps-item-container > .ant-steps-item-tail {
    display: none;
  }

  .ant-progress-text > div {
    display: flex;
    justify-content: center;
  }
`;

export const StyledAvatar = styled(Avatar)`
  background-color: ${({ theme }) => theme.palette.dark.dark_4};
`;

export const AvatarWrapper = styled.div<{ waiting: boolean; blocked?: boolean }>`
  ${StyledAvatar} {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 0.5px solid ${({ theme }) => theme.palette.dark.dark_16};

    ${({ waiting }) =>
      waiting &&
      css`
        filter: grayscale(100%);
      `}

    &.step-closed {
      border: none;
    }
  }
`;

export const StyledTooltip = styled(Tooltip)`
  &&&&& {
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    font-weight: 700;
    color: ${({ theme }) => theme.palette.dark.dark_40};
    transition: color 0.2s;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-align: center;

    .ant-tooltip-inner {
      font-weight: 300;
    }
    .ant-tooltip-arrow {
      left: 50%;
    }
  }
`;

export const PercentText = styled.span<{ finished: boolean }>`
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  color: ${({ theme }) => theme.palette.dark.dark_40};
  flex-shrink: 0;
  margin-top: 8px;

  ${({ finished }) =>
    finished &&
    css`
      &&&&&& {
        color: ${({ theme }) => theme.palette.success.primary};
      }
    `}
`;

export const StyledProgress = styled.div`
  display: flex;
  align-items: flex-end;
  margin-top: 4px;
`;

export const StyledBadge = styled(Badge)`
  &&&& .ant-scroll-number-custom-component {
    top: auto;
    bottom: 0;
    left: 0;
    margin: auto;
    transform: translate(0, 50%);
    z-index: 1;
  }
`;

export const DescriptionOverlay = styled.div<{ waiting: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  &:hover {
    ${StyledAvatar} {
      ${({ waiting }) =>
        !waiting &&
        css`
          &:not(.step-closed) {
            &::before {
              content: '';
              width: 100%;
              height: 100%;
              position: absolute;
              background-color: ${({ theme }) => theme.palette.mask.weak};
              top: 0;
              left: 0;
              transition: background-color 0.2s;
            }
            &::after {
              content: '';
              width: 24px;
              height: 24px;
              background-image: url(${Eye});
              position: absolute;
              z-index: 2;
              transition: background-image 0.2s;
            }
          }
        `}
    }
  }
`;

export const StyledTooltipWrapper = styled.span``;
