import React, { lazy, useEffect, useMemo } from 'react';
import { Route, Switch } from 'react-router-dom';
import { observer } from 'mobx-react';
import { useAppNavigator, useMenuCollapsed } from 'utils';
import { sendAnalyticsEvent } from 'utils/analytics';
import { RouteTemplates, ScreenNames } from '_constants';
import store from 'store';
import { coursesApi } from 'api';
import CompleteModal from 'views/Stage/CompleteModal';
import { ModalOptions } from 'types/completeModalTypes';
import { FinishModal } from 'components';
import { LearningTracksModalOptions } from 'types/completeModalLearningTracks';

const Poll = lazy(() => import('views/Poll'));
const Stage = lazy(() => import('views/Stage'));
const Booking = lazy(() => import('views/MyBookings/Booking'));

const StageRouter: React.FC = () => {
  useMenuCollapsed();
  const { data: course, getStageIsExist, getStage } = store.DomainStore.course;
  const { track } = store.DomainStore.learningTrack;
  const { show404placeholder } = store.UIStore.layout;
  const { finishCourseModal } = store.UIStore.course;
  const {
    params: { courseId, stageId, trackStepId, trackId },
    goToCourse,
    goToLearningTrack,
  } = useAppNavigator();
  const currentStage = useMemo(() => getStage(stageId), [getStage, stageId]);

  useEffect(() => {
    coursesApi.stageOpened(Number(courseId), Number(stageId), trackId);

    let contentType = 'catalog';
    if (currentStage?.scormId) {
      contentType = 'scorm';
    }
    if (currentStage?.taskId) {
      contentType = 'task';
    }
    if (course && course.id === courseId && currentStage) {
      sendAnalyticsEvent('screen_view', {
        firebase_screen: ScreenNames.COURSE_STAGE,
        course_id: courseId,
        stage_id: stageId,
        course_name: course.name,
        stage_name: currentStage.name,
        content_type: contentType,
        ...(trackId && track
          ? {
              track_id: track.id,
              track_name: track.name,
            }
          : {}),
      });
    }
  }, [courseId, stageId, course, currentStage, trackId, track]);

  useEffect(() => {
    if (!stageId || !course || getStageIsExist(Number(stageId))) return;
    show404placeholder();
  }, [course, getStageIsExist, show404placeholder, stageId]);

  const onFinishCourse = () => {
    finishCourseModal.hide();
    if (trackStepId) {
      goToLearningTrack(trackId);
    } else {
      goToCourse(courseId);
    }

    sendAnalyticsEvent('course_completed', {
      course_id: course?.id,
      course_name: course?.name,
      ...(trackId && track
        ? {
            track_id: track.id,
            track_name: track.name,
          }
        : {}),
    });
  };

  return (
    <>
      {trackStepId ? (
        <FinishModal
          visible={finishCourseModal.visible}
          onClose={finishCourseModal.hide}
          onOk={() => onFinishCourse()}
          title={course?.finalScreen?.title}
          text={course?.finalScreen?.text}
          option={LearningTracksModalOptions.COURSE}
        />
      ) : (
        <CompleteModal
          visible={finishCourseModal.visible}
          onClose={finishCourseModal.hide}
          onOk={() => onFinishCourse()}
          title={course?.finalScreen?.title}
          text={course?.finalScreen?.text}
          option={ModalOptions.COURSE}
        />
      )}
      <Switch>
        <Route
          path={[RouteTemplates.poll, RouteTemplates.myLearningTrackPoll, RouteTemplates.menuCoursePoll]}
          component={Poll}
        />
        <Route
          path={[
            RouteTemplates.courseBooking,
            RouteTemplates.courseBookingRegistrations,
            RouteTemplates.menuCourseBooking,
            RouteTemplates.menuCourseBookingRegistrations,
            RouteTemplates.myLearningTrackCourseBooking,
            RouteTemplates.myLearningTrackCourseBookingRegistrations,
          ]}
          component={Booking}
        />
        <Route
          path={[
            RouteTemplates.scorm,
            RouteTemplates.catalog,
            RouteTemplates.myLearningTrackScorm,
            RouteTemplates.myLearningTrackCatalog,
            RouteTemplates.stageTask,
            RouteTemplates.myLearningTrackTask,
            RouteTemplates.menuCourseScorm,
            RouteTemplates.menuCourseCatalog,
            RouteTemplates.menuCourseStageTask,
          ]}
          component={Stage}
        />
      </Switch>
    </>
  );
};

export default observer(StageRouter);
