import { types } from 'mobx-state-tree';
import { RelativeDeadlineUnits } from '_constants';

const MCourseDetails = types.model({
  id: types.number,
  name: types.string,
  coverThumbnailUrl: types.string,
  assignedNumber: types.number,
  notStartedNumber: types.number,
  inProgressNumber: types.number,
  finishedNumber: types.number,
  averagePoints: types.number,
  expiredCount: types.maybeNull(types.number),
  maxPoints: types.number,
  fixedDeadlineDate: types.maybeNull(types.string),
  relativeDeadlineUnits: types.maybeNull(
    types.enumeration<RelativeDeadlineUnits>('RelativeDeadlineUnits', Object.values(RelativeDeadlineUnits)),
  ),
  relativeDeadlineValue: types.maybeNull(types.number),
});

export default MCourseDetails;
