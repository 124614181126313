import React, { useRef } from 'react';
import { observer } from 'mobx-react';
import { RichText } from 'components';
import {
  TCatalogChild,
  TCatalogText,
  TCatalog,
  TCatalogAttendee,
  TCatalogGroup,
  TCatalogFile,
  TCatalogLink,
  TCatalogPoll,
  TCatalogMedia,
  TCatalogBooking,
} from 'types';
import { CatalogElementTypes, ViewOptions } from 'models/Catalogs/constants';
import { UIStore } from 'store';
import Catalog from './Catalog';
import Poll from './CatalogPoll';
import CatalogLink from './Link';
import Attendee from './Attendees/Attendee';
import GroupAttendees from './Attendees/AttendeesGroup';
import File from './File';
import Video from './Video';
import Booking from './CatalogBooking';
// Пока в админке нет возможности добавлять аудио
// import Audio from './Audio';
import {
  CatalogElementWrapper,
  ImageRowWrapper,
  TextRowWrapper,
  GroupRowWrapper,
  BookingElementWrapper,
} from './CatalogElement.styles';

interface Props {
  catalogElement: TCatalogChild;
  onImageClick?: (source: string) => void;
}

const CatalogElement: React.FC<Props> = ({ catalogElement, onImageClick }) => {
  const ref = useRef<HTMLDivElement>(null);

  const { setLoadingCatalogRichTextId, removeLoadingCatalogRichTextId } = UIStore.course;

  const renderWrapper = () => {
    const { LINK, CATALOG, FILE, POLL, ATTENDEE, GROUP, BOOKING } = CatalogElementTypes;
    const { type } = catalogElement;
    if (type === FILE || type === POLL || type === ATTENDEE) {
      return TextRowWrapper;
    }
    if (catalogElement.type === LINK || catalogElement.type === CATALOG) {
      switch ((catalogElement as TCatalogLink | TCatalog).viewOptions) {
        case ViewOptions.IMAGE:
          return ImageRowWrapper;
        case ViewOptions.MEDIA:
          return TextRowWrapper;
        default:
          return TextRowWrapper;
      }
    }
    if (type === GROUP) {
      return GroupRowWrapper;
    }
    if (type === BOOKING) {
      return BookingElementWrapper;
    }
    return CatalogElementWrapper;
  };

  const renderElement = () => {
    switch (catalogElement.type) {
      case CatalogElementTypes.TEXT:
        return (
          <RichText
            onFinishLoading={() => removeLoadingCatalogRichTextId(catalogElement.id)}
            onStartLoading={() => setLoadingCatalogRichTextId(catalogElement.id)}
            onImageClick={onImageClick}
            content={(catalogElement as TCatalogText).value || ''}
            showPreview={false}
          />
        );
      case CatalogElementTypes.CATALOG:
        return <Catalog catalog={catalogElement as TCatalog} />;
      case CatalogElementTypes.LINK:
        return <CatalogLink link={catalogElement as TCatalogLink} />;
      case CatalogElementTypes.FILE:
        return <File file={catalogElement as TCatalogFile} />;
      case CatalogElementTypes.POLL:
        return <Poll poll={catalogElement as TCatalogPoll} />;
      case CatalogElementTypes.ATTENDEE:
        return <Attendee attendee={catalogElement as TCatalogAttendee} />;
      case CatalogElementTypes.GROUP:
        return <GroupAttendees group={catalogElement as TCatalogGroup} />;
      case CatalogElementTypes.VIDEO:
        return <Video video={catalogElement as TCatalogMedia} />;
      case CatalogElementTypes.BOOKING:
        return <Booking booking={catalogElement as TCatalogBooking} />;
      // case CatalogElementTypes.AUDIO:
      //   return <Audio audio={catalogElement as TCatalogMedia} />;
      default:
        return null;
    }
  };

  const Wrapper = renderWrapper();

  return <Wrapper ref={ref}>{renderElement()}</Wrapper>;
};

export default observer(CatalogElement);
