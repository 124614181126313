/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["self"] }] */
import { myTeamApi, coursesApi } from 'api';
import { cast, flow, types, getRoot } from 'mobx-state-tree';
import { MGroupDetails, MActivityInfo, MGroup } from 'models/MyTeam';
import { TGroupsResponse } from 'types/myTeamApiTypes';
import { TStore, TResponded } from 'types';
import { ActivityRange } from '_constants';
import { MGroupAttendee } from 'models/Catalogs';
import { TAttendeeResponse } from 'types/coursesApiTypes';
import { isTalentRocksApp } from 'env';

const MyTeam = types
  .model({
    groupId: types.maybeNull(types.number),
    groupsInfo: types.maybeNull(types.array(MGroupDetails)),
    groups: types.map(MGroup),
    attendeesInfo: types.map(MGroupAttendee),
    allActivity: types.optional(MActivityInfo, {}),
    rangeActivity: types.optional(types.string, ActivityRange.WEEK),
  })
  .views((self) => ({
    get myTeamUIStore() {
      return getRoot<TStore>(self).UIStore.myTeam;
    },
  }))
  .actions((self) => ({
    createGroup: (id: number) => {
      self.groups.put(MGroup.create({ groupId: id }));
    },
    deleteGroup: (id: number) => {
      self.groups.delete(String(id));
    },
    fetchGroups: flow(function* fetchGroups() {
      self.myTeamUIStore.setIsFetchingGroups(true);
      const { data, hasError }: TGroupsResponse = isTalentRocksApp
        ? yield myTeamApi.groupApi.getGroups()
        : yield myTeamApi.groupApi.getMobileGroups();
      if (data) {
        self.groupsInfo = cast(data);
      }
      self.myTeamUIStore.setIsFetchingGroups(false);
      return !hasError;
    }),
    fetchAttendee: flow(function* fetchAttendee(id: number, trackId = '') {
      self.myTeamUIStore.setIsFetchingAttendee(true);
      const { data, hasError }: TAttendeeResponse = yield coursesApi.getAttendee(id, trackId);
      if (data) {
        self.attendeesInfo.put(data);
      }
      self.myTeamUIStore.setIsFetchingAttendee(false);
      return !hasError;
    }),
    generateReport: flow(function* generateReport() {
      self.myTeamUIStore.setIsFetching(true);
      const response: TResponded<null> = isTalentRocksApp
        ? yield myTeamApi.generateReport()
        : yield myTeamApi.mobileGenerateReport();
      self.myTeamUIStore.setIsFetching(false);
      return response;
    }),
  }))
  .actions((self) => ({
    setGroupId: (id: number) => {
      if (!self.groups.get(String(id))) {
        self.createGroup(id);
      }
      self.groupId = id;
    },
    fetchMyTeamRequiredData: flow(function* fetchMyTeamRequiredData() {
      self.myTeamUIStore.setIsFetching(true);
      const responses: boolean[] = yield Promise.all([self.allActivity.fetchActivity()]);
      self.myTeamUIStore.setIsFetching(false);
      return responses.every((r) => r);
    }),
    fetchMyTeamActivityRequiredData: flow(function* fetchMyTeamActivityRequiredData() {
      self.allActivity.createAttendeesGroup(ActivityRange.MONTH);
      self.allActivity.createAttendeesGroup(ActivityRange.WEEK);
      self.myTeamUIStore.setIsFetching(true);
      const responses: boolean[] = yield Promise.all([
        self.allActivity.attendees.get(ActivityRange.MONTH)?.fetchActiveAttendees(),
        self.allActivity.attendees.get(ActivityRange.MONTH)?.fetchInactiveAttendees(),
        self.allActivity.attendees.get(ActivityRange.MONTH)?.fetchAttendeesCount(),
        self.allActivity.attendees.get(ActivityRange.WEEK)?.fetchActiveAttendees(),
        self.allActivity.attendees.get(ActivityRange.WEEK)?.fetchInactiveAttendees(),
        self.allActivity.attendees.get(ActivityRange.WEEK)?.fetchAttendeesCount(),
      ]);
      self.myTeamUIStore.setIsFetching(false);
      return responses.every((r) => r);
    }),
    setRangeActivity: (range: string) => {
      self.rangeActivity = range;
    },
  }));

export default MyTeam;
