import { types } from 'mobx-state-tree';
import { ParticipationDeadlineStatus } from '_constants';
import { LearningTracksStatuses } from './constants';

const MLearningTrackInfo = types.model({
  id: types.string,
  name: types.string,
  coverUrl: types.string,
  coverThumbnailUrl: types.string,
  status: types.enumeration<LearningTracksStatuses>('Status', Object.values(LearningTracksStatuses)),
  totalSteps: types.number,
  completedSteps: types.number,
  viewInAdminMode: types.boolean,
  openDate: types.maybeNull(types.string),
  completionDate: types.maybeNull(types.string),
  participationDeadlineStatus: types.enumeration<ParticipationDeadlineStatus>(
    'DeadlineStatus',
    Object.values(ParticipationDeadlineStatus),
  ),
  deadlineDate: types.maybeNull(types.string),
});

export default MLearningTrackInfo;
