import { types } from 'mobx-state-tree';
import { ParticipationDeadlineStatus } from '_constants';
import { MStage } from './MStage';

const MFinalScreen = types.model({
  isEnabled: types.boolean,
  title: types.maybeNull(types.string),
  text: types.maybeNull(types.string),
});

const MProgress = types.model({
  isEnabled: types.boolean,
  text: types.maybeNull(types.string),
});

const MCourse = types.model({
  id: types.identifierNumber,
  name: types.maybeNull(types.string),
  coverUrl: types.maybeNull(types.string),
  coverThumbnailUrl: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
  isFreeOrderAllowed: types.boolean,
  isAdmin: types.boolean,
  progress: types.maybeNull(MProgress),
  finalScreen: types.maybeNull(MFinalScreen),
  stages: types.array(MStage),
  trackId: types.maybeNull(types.string),
  trackStepId: types.maybeNull(types.string),
  participationDeadlineStatus: types.enumeration<ParticipationDeadlineStatus>(
    'DeadlineStatus',
    Object.values(ParticipationDeadlineStatus),
  ),
  completionDate: types.maybeNull(types.string),
  deadlineDate: types.maybeNull(types.string),
  certificateId: types.maybeNull(types.string),
});

export default MCourse;
