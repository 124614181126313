import { Progress, Typography } from 'antd';
import { ReactComponent as CheckmarkIcon } from 'assets/icons/checkmarkEllipse.svg';
import { ErrorImageOverlay } from 'components/Image/Image.styles';
import styled from 'styled-components';
import { Grid } from '_constants';

export const CourseCardImageOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(
      180deg,
      ${({ theme }) => theme.palette.shadow.barely},
      ${({ theme }) => theme.palette.shadow.darken}
    );
  }
  & .ant-image {
    width: 100%;
    height: 100%;
    transition: transform 0.4s;

    & .ant-image-img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      object-position: center;
    }

    &.ant-image-error {
      position: absolute;
    }
  }
  ${ErrorImageOverlay} {
    cursor: pointer;
    transition: transform 0.4s;
  }
`;

interface IWrapper {
  isInDashboard?: boolean;
}

export const Wrapper = styled.article<IWrapper>`
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 362px;
  max-width: 362px;
  border-radius: 16px;
  aspect-ratio: 5 / 3;
  /* blocks the straightening of corners in Safari */
  mask-image: radial-gradient(white, black);
  &:hover {
    & .ant-image,
    ${ErrorImageOverlay} {
      cursor: pointer;
      transform: scale(1.1);
    }
  }
  @media (max-width: ${Grid.XS}px) {
    width: calc(100vw - 57px);
  }
`;

export const Body = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  flex: auto;
  flex-direction: column;
  justify-content: flex-end;
  padding: 0 20px;
  margin-bottom: 16px;
`;

export const Header = styled.div`
  position: absolute;
  width: 100%;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  padding: 12px 12px 0;
`;

export const Info = styled.div<{ isCompleted?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 400;
  font-size: 12px;
  font-family: 'Roboto', sans-serif;
  line-height: 12px;
  margin-bottom: ${({ isCompleted }) => (isCompleted ? 0 : '8px')};
`;

export const StagesWrapper = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.palette.white.primary};
`;

interface IPercent {
  isSuccess?: boolean;
}

export const Percent = styled.div<IPercent>`
  color: ${({ isSuccess, theme }) => (isSuccess ? theme.palette.success.primary : theme.palette.white.primary)};
`;

export const CompletedLabel = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.palette.success.primary};
  line-height: 22px;
  letter-spacing: 0.2px;
`;

export const StyledCheckmarkIcon = styled(CheckmarkIcon)`
  width: 14px;
  height: 14px;
  margin-right: 8px;
`;

export const Title = styled(Typography.Paragraph)`
  &&& {
    &.ant-typography {
      margin-bottom: 16px;
      color: ${({ theme }) => theme.palette.white.primary};
      font-weight: 700;
      font-size: 18px;
      font-family: 'Roboto', sans-serif;
      line-height: 24px;
    }
  }
`;

export const StyledProgress = styled(Progress)`
  &&& {
    z-index: 2;
    &.ant-progress {
      overflow: hidden;
      line-height: 0;
    }
    .ant-progress-inner {
      background-color: transparent;
      vertical-align: bottom;
    }
  }
`;

export const StyledTrackIcon = styled.div`
  width: 32px;
  height: 32px;
  backdrop-filter: blur(8px);
  border-radius: 8px;
  background-color: ${({ theme }) => theme.palette.mask.lighter};
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const HeaderLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;

export const LabelsLine = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;
