import styled, { css } from 'styled-components';
import { Avatar, Steps } from 'antd';

import DotsVertical from 'assets/icons/dots-vertical.svg';
import Tooltip from 'components/Tooltip';

export const StepsOverlay = styled.div`
  margin-top: 40px;
  width: 100%;
`;

export const StyledSteps = styled(Steps)`
  &&& {
    .ant-steps-item-container {
      margin-bottom: 24px;
      padding: 20px;
      border-radius: 16px;

      &:hover,
      &:active {
        background-color: ${({ theme }) => theme.palette.dark.dark_2};
        cursor: pointer;
      }
    }

    .ant-steps-item-tail {
      flex-direction: column;
      justify-content: flex-end;
      align-items: center;

      width: 94px;
      padding: 0;
      top: -2px;
      left: 0;

      &::before {
        content: '';
        position: absolute;
        z-index: 2;
        width: 100%;
        height: 20px;
        cursor: default;
      }

      &::after {
        content: '';
        display: block;
        width: 4px;
        height: 20px;
        background-color: ${({ theme }) => theme.palette.dark.dark_16};
        mask: url(${DotsVertical}) no-repeat 50%;

        transition: background-color 0.2s;
      }
    }

    .ant-steps-item-icon {
      margin-right: 24px;
      width: 56px;
      height: 56px;
    }

    .ant-steps-item-title {
      margin-top: -4px;
      font-family: 'Roboto', sans-serif;
      color: ${({ theme }) => theme.palette.dark.dark_40};
      transition: color 0.2s;
    }

    .ant-steps-item-description > span {
      padding: 0;
      font-family: 'Roboto', sans-serif;
      font-size: 18px;
      font-weight: 500;
      color: ${({ theme }) => theme.palette.dark.dark_40};
      transition: color 0.2s;

      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    .ant-steps-item-finish {
      .ant-steps-item-tail {
        &::after {
          background-color: ${({ theme }) => theme.palette.success.primary};
        }
      }
      .ant-steps-item-title {
        color: ${({ theme }) => theme.palette.success.primary};
      }
    }

    .ant-steps-item-finish,
    .ant-steps-item-process,
    .ant-steps-item-active {
      .ant-steps-item-description > span {
        color: ${({ theme }) => theme.palette.dark.main};
      }
    }
  }

  .ant-steps-item:not(:last-child) > .ant-steps-item-container > .ant-steps-item-tail {
    display: flex;
  }

  .ant-steps-item:last-child > .ant-steps-item-container > .ant-steps-item-tail {
    display: none;
  }
`;

export const StyledAvatar = styled(Avatar)`
  background-color: ${({ theme }) => theme.palette.dark.dark_4};
`;

export const AvatarWrapper = styled.div<{ finished: boolean; waiting: boolean; blocked?: boolean }>`
  ${StyledAvatar} {
    border: 1px solid
      ${({ theme, finished }) => (finished ? theme.palette.success.primary : theme.palette.dark.dark_40)};
    ${({ waiting }) =>
      waiting &&
      css`
        filter: grayscale(100%);
      `}

    &.step-closed {
      border: none;
    }
  }
`;

export const StyledTooltip = styled(Tooltip)`
  .ant-tooltip-inner {
    font-weight: 300;
  }

  .ant-tooltip-arrow {
    left: 50%;
  }
`;

export const PercentText = styled.span`
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: ${({ theme }) => theme.palette.dark.dark_40};
  margin-left: 8px;
  min-width: 30px;
  flex-shrink: 0;
`;

export const StyledProgress = styled.div`
  display: flex;
  align-items: flex-end;
  margin-top: 4px;
`;

export const StyledTooltipWrapper = styled.span`
  width: fit-content;
`;
