import { types } from 'mobx-state-tree';
import { ParticipationDeadlineStatus } from '_constants';

export enum CourseStatuses {
  NOT_STARTED = 'notStarted',
  IN_PROGRESS = 'inProgress',
  COMPLETED = 'completed',
}

const MCourseInfo = types.model({
  id: types.number,
  openDate: types.maybeNull(types.string),
  assignmentDate: types.maybeNull(types.string),
  completionDate: types.maybeNull(types.string),
  coverUrl: types.maybeNull(types.string),
  coverThumbnailUrl: types.maybeNull(types.string),
  name: types.maybeNull(types.string),
  stagesCount: types.number,
  stagesPassed: types.number,
  status: types.maybeNull(types.enumeration<CourseStatuses>('Status', Object.values(CourseStatuses))),
  viewInAdminMode: types.maybeNull(types.boolean),
  participationDeadlineStatus: types.enumeration<ParticipationDeadlineStatus>(
    'DeadlineStatus',
    Object.values(ParticipationDeadlineStatus),
  ),
  deadlineDate: types.maybeNull(types.string),
});

export default MCourseInfo;
