import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'components.Certificate.title',
    defaultMessage: 'Вы успешно прошли обучение!',
  },
  status: {
    id: 'components.Certificate.status',
    defaultMessage: 'Сертификат уже доступен',
  },
  downloadButton: {
    id: 'components.Certificate.downloadButton',
    defaultMessage: 'Скачать',
  },
  certificate: {
    id: 'components.Certificate.certificate',
    defaultMessage: 'Сертификат',
  },
});
