import React, { useState } from 'react';
import { Badge, StepsProps } from 'antd';
import { StepIconRender } from 'rc-steps/lib/Steps';
import { useIntl } from 'react-intl';

import { ReactComponent as CheckmarkEllipse } from 'assets/icons/checkmarkEllipse.svg';
import { ReactComponent as LockIcon } from 'assets/icons/lock.svg';

import { TStep } from 'types';
import { LearningTrackStepsContentTypes, LearningTrackStepsStatuses } from 'models/LearningTracks/constants';
import { sendAnalyticsEvent, useAppNavigator, useVisibility, useHoverEffectCheck } from 'utils';
import { CourseSources, ProgressBarWidth } from '_constants';
import { RejectTypes, TRejectTypes } from 'views/LearningTracks/constants';
import { TrackRejectModal } from 'components';
import Progress from 'components/Progress';
import store from 'store';
import messages from './messages';
import {
  StepsOverlay,
  AvatarWrapper,
  StyledAvatar,
  StyledSteps,
  StyledTooltip,
  StyledProgress,
  PercentText,
  StyledTooltipWrapper,
} from './Steps.styles';

interface OwnProps {
  steps?: TStep[];
  viewInAdminMode?: boolean;
}

type Props = StepsProps & OwnProps;

const Steps: React.FC<Props> = ({ steps = [], viewInAdminMode, ...props }) => {
  const intl = useIntl();
  const rejectModalVisibility = useVisibility();
  const hasHover = useHoverEffectCheck();
  const [rejectModalType, setRejectModalType] = useState<TRejectTypes | null>(null);
  const {
    params: { trackId },
    goToLearningTrackCourse,
  } = useAppNavigator();
  const {
    learningTrack: { track, isShowStepClosedIcon, fetchIsLearningTrackStepOpened },
  } = store.DomainStore;

  const onTooltipClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (hasHover) {
      return;
    }

    event.stopPropagation();
  };

  const renderIcon: StepIconRender = ({ node, status }) => {
    const finished = status === 'finish';
    const waiting = status === 'wait';

    return (
      <Badge count={finished ? <CheckmarkEllipse /> : undefined} offset={[-10, 10]}>
        <AvatarWrapper finished={finished} waiting={waiting}>
          {node}
        </AvatarWrapper>
      </Badge>
    );
  };

  const renderSteps = () =>
    steps.map(({ id, name, coverThumbnailUrl, status, content, progress }, idx) => {
      const percent = Math.round(progress * 100);

      const Description = (
        <>
          <StyledTooltipWrapper onClick={onTooltipClick}>
            <StyledTooltip
              title={name}
              placement="bottom"
              getPopupContainer={(trigger) => trigger}
              trigger={['hover', 'click']}
            >
              {name}
            </StyledTooltip>
          </StyledTooltipWrapper>
          {(status === LearningTrackStepsStatuses.IN_PROGRESS || status === LearningTrackStepsStatuses.NOT_STARTED) && (
            <StyledProgress>
              <Progress percent={Math.max(ProgressBarWidth.XS, percent)} />
              <PercentText>{`${percent}%`}</PercentText>
            </StyledProgress>
          )}
        </>
      );

      const Icon = (
        <StyledAvatar
          size={56}
          {...(status === LearningTrackStepsStatuses.CLOSED && isShowStepClosedIcon(id)
            ? { icon: <LockIcon />, className: 'step-closed' }
            : { src: coverThumbnailUrl })}
        />
      );

      const getStatus = () => {
        if (status === LearningTrackStepsStatuses.NOT_AVAILABLE) {
          return 'wait';
        }
        if (status === LearningTrackStepsStatuses.IN_PROGRESS || status === LearningTrackStepsStatuses.NOT_STARTED) {
          return 'process';
        }
        if (status === LearningTrackStepsStatuses.COMPLETED) {
          return 'finish';
        }
        return undefined;
      };

      const onStepClick = async () => {
        if (trackId && track) {
          sendAnalyticsEvent('track_step_opened', {
            track_id: track.id,
            track_name: track.name,
            course_id: content?.id,
            course_name: name,
          });
        }

        if (content.type === LearningTrackStepsContentTypes.COURSE) {
          sendAnalyticsEvent('course_opened', {
            source: CourseSources.TRACK,
            course_id: content?.id,
            course_name: name,
            ...(trackId && track
              ? {
                  track_id: track.id,
                  track_name: track.name,
                }
              : {}),
          });
        }

        if (viewInAdminMode && content.id) {
          const { isStepOpened } = await fetchIsLearningTrackStepOpened(trackId, id);
          if (isStepOpened) {
            goToLearningTrackCourse(content.id, id);
          }
        } else if (
          status === LearningTrackStepsStatuses.NOT_AVAILABLE ||
          (status === LearningTrackStepsStatuses.CLOSED && !isShowStepClosedIcon(id))
        ) {
          setRejectModalType(RejectTypes.YET_UNAVAILABLE);
          rejectModalVisibility.show();
        } else if (status === LearningTrackStepsStatuses.CLOSED && isShowStepClosedIcon(id)) {
          setRejectModalType(RejectTypes.CLOSED);
          rejectModalVisibility.show();
        } else if (content.id) {
          const { isStepOpened } = await fetchIsLearningTrackStepOpened(trackId, id);
          if (isStepOpened) {
            goToLearningTrackCourse(content.id, id);
          }
        }
      };

      return (
        <StyledSteps.Step
          key={id}
          title={`${intl.formatMessage(messages.step)} ${idx + 1}`}
          description={Description}
          icon={Icon}
          stepIcon={renderIcon}
          status={getStatus()}
          onClick={onStepClick}
        />
      );
    });

  return (
    <StepsOverlay>
      <StyledSteps {...props} direction="vertical">
        {renderSteps()}
      </StyledSteps>
      <TrackRejectModal
        rejectType={rejectModalType}
        visible={rejectModalVisibility.visible}
        onClose={rejectModalVisibility.hide}
      />
    </StepsOverlay>
  );
};

export default Steps;
