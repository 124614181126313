import { TThemePalette } from 'types';

const Palette: TThemePalette = {
  primary: { main: '#6100ff' },
  dominant: '#ffffff00',
  additionalDominant: '#ffffff00',
  dark: {
    main: '#08003b',
    dark_2: '#fafafb',
    dark_4: '#f5f5f7',
    dark_6: '#f0f0f3',
    dark_8: '#ebebef',
    dark_10: '#e6e5eb',
    dark_12: '#e1e0e7',
    dark_16: '#d7d6e0',
    dark_20: '#ceccd8',
    dark_24: '#c4c2d0',
    dark_30: '#b5b2c5',
    dark_40: '#9c99b1',
    dark_50: '#83809d',
    dark_68: '#57527A',
    dark_76: '#08003bc2',
  },
  violet: {
    main: '#4700BB',
    violet_76: '#733DCB',
    violet_68: '#9452FF',
    violet_40: '#C099FF',
    violet_20: '#DACCF1',
    violet_4: '#F9F5FF',
  },
  blueGrey: {
    main: '#8f9dad',
    blueGrey_15: '#eef0f3',
  },
  notify: '#ff4d4f',
  rating: '#ffc000',
  trafficLight: {
    red: '#e11515',
    amber: '#fb8600',
    green: '#25ab4a',
  },
  scrollbar: '#ced0d87f',
  success: {
    primary: '#0dc268',
    secondary: 'rgba(13, 194, 104, 0.1)',
    accent: 'rgba(13, 194, 104, 0.15)',
  },
  error: {
    primary: '#ed0a34',
    secondary: 'rgba(237, 10, 52, 0.1)',
    error_15: '#ffdee2',
    error_40: '#f89dae',
  },
  warning: { primary: '#fb8600', secondary: '#be6100' },
  blue: 'rgb(53, 106, 255)',
  white: {
    primary: '#ffffff',
    secondary: 'rgba(255, 255, 255, 0.5)',
    white_12: 'rgba(255, 255, 255, 0.12)',
    white_20: 'rgba(255, 255, 255, 0.20)',
    white_90: 'rgba(255, 255, 255, 0.90)',
    white_96: 'rgba(255, 255, 255, 0.96)',
  },
  black: { primary: '#000000', secondary: 'rgba(0, 0, 0, 0.5)' },
  disable: { primary: '#e4e4e4', secondary: '#989899' },
  shadow: {
    main: '#08003b14',
    dark: '#08003b40',
    light: 'rgba(8, 0, 59, 0.06)',
    weak: 'rgba(8, 0, 59, 0.1)',
    darken: 'rgba(0, 0, 0, 0.85)',
    barely: 'rgba(0, 0, 0, 0.05)',
    dark_3: 'rgba(16, 24, 40, 0.03)',
    dark_1: 'rgba(16, 24, 40, 0.01)',
  },
  mask: {
    main: 'rgba(0, 0, 0, 0.6)',
    slightlyDark: 'rgba(0, 0, 0, 0.67)',
    dark: 'rgba(0, 0, 0, 0.8)',
    light: 'rgba(0, 0, 0, 0.35)',
    lighter: 'rgba(0, 0, 0, 0.25)',
    weak: 'rgba(0, 0, 0, 0.2)',
    weaker: 'rgba(0, 0, 0, 0.12)',
    barely: 'rgba(0, 0, 0, 0.08)',
    slightly: 'rgba(0, 0, 0, 0.02)',
    transparent: 'rgba(0, 0, 0, 0)',
    whiteBasedTransparent: 'rgba(255, 255, 255, 0)',
    whiteALittleTransparent: 'rgba(255, 255, 255, 0.9)',
  },
  hoverBackground: '#f7f8fa',
  myTeamAvatarColors: ['#57c048', '#b435d7', '#426cf6'],
};

export default Palette;
