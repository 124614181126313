import { observer } from 'mobx-react';
import React, { lazy, Suspense, useEffect, useMemo, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { DomainStore } from 'store';
import { RouteTemplates } from '_constants';
import { PrivateMenuRoute, Spinner } from 'components';
import CoursesRouter from 'views/Courses/CoursesRouter';
import LearningTracksRouter from 'views/LearningTracks/LearningTracksRouter';
import { useAppNavigator, getComponentPath } from 'utils';

const MyTeam = lazy(() => import('views/MyTeam'));
const MyPolls = lazy(() => import('views/MyPolls'));
const Poll = lazy(() => import('views/Poll'));
const MenuCatalog = lazy(() => import('views/MenuCatalog'));
const Certificates = lazy(() => import('views/Certificates'));
const PersonalGamification = lazy(() => import('views/Gamification/PersonalGamification'));
const GroupGamification = lazy(() => import('views/Gamification/GroupGamification'));
const MyBookings = lazy(() => import('views/MyBookings/MyBookings'));
const Frame = lazy(() => import('views/Frame'));
const MyTasks = lazy(() => import('views/MyTasks/MyTasks'));
const Schedule = lazy(() => import('views/Schedule'));
const Dashboard = lazy(() => import('views/Dashboard'));
const Course = lazy(() => import('views/Course/CourseRouter'));

const MainContent: React.FC = () => {
  const {
    isMyTeamAvailable,
    isGamificationAvailable,
    isGroupGamificationAvailable,
    isMyPollsAvailable,
    isMyCoursesAvailable,
    isMyBookingsAvailable,
    isMyLearningTracksAvailable,
    isMyTasksAvailable,
    isScheduleAvailable,
    isDashboardAvailable,
    data: components,
    fetchComponents,
  } = DomainStore.components;
  const {
    params: { alias },
  } = useAppNavigator();
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    const init = async () => {
      setLoading(true);
      await fetchComponents();
      setLoading(false);
    };
    init();
  }, [fetchComponents]);

  const redirectPath = useMemo(() => {
    if (isLoading) return RouteTemplates.alias;
    const firstComponent = components?.[0];
    if (isDashboardAvailable || !firstComponent) return RouteTemplates.dashboard;
    return getComponentPath(firstComponent, alias);
  }, [isLoading, isDashboardAvailable, alias, components]);

  if (isLoading) return null;
  return (
    <Suspense fallback={<Spinner fullscreen />}>
      <Switch>
        <Route path={[RouteTemplates.menuPoll]} component={Poll} />
        <Route path={[RouteTemplates.myPolls]}>
          <PrivateMenuRoute key="myPolls" available={isMyPollsAvailable}>
            <MyPolls />
          </PrivateMenuRoute>
        </Route>
        <Route
          path={[
            RouteTemplates.course,
            RouteTemplates.allCourses,
            RouteTemplates.inProgressCourses,
            RouteTemplates.availableCourses,
            RouteTemplates.completedCourses,
          ]}
        >
          <PrivateMenuRoute key="courses" available={isMyCoursesAvailable}>
            <CoursesRouter />
          </PrivateMenuRoute>
        </Route>
        <Route path={RouteTemplates.menuCourse} component={Course} />

        <Route path={RouteTemplates.myTeam}>
          <PrivateMenuRoute key="myTeam" available={isMyTeamAvailable}>
            <MyTeam />
          </PrivateMenuRoute>
        </Route>
        <Route path={[RouteTemplates.menuCatalog]} component={MenuCatalog} />
        <Route
          path={[RouteTemplates.gamification]}
          render={() => (
            <PrivateMenuRoute key="gamification" available={isGamificationAvailable}>
              <PersonalGamification />
            </PrivateMenuRoute>
          )}
        />
        <Route path={[RouteTemplates.groupGamification]}>
          <PrivateMenuRoute key="groupGamification" available={isGroupGamificationAvailable}>
            <GroupGamification />
          </PrivateMenuRoute>
        </Route>
        <Route path={[RouteTemplates.myBookings]}>
          <PrivateMenuRoute key="myBookings" available={isMyBookingsAvailable}>
            <MyBookings />
          </PrivateMenuRoute>
        </Route>
        <Route path={[RouteTemplates.frame]} component={Frame} />
        <Route path={[RouteTemplates.myTasks]} component={MyTasks}>
          <PrivateMenuRoute key="myTasks" available={isMyTasksAvailable}>
            <MyTasks />
          </PrivateMenuRoute>
        </Route>
        <Route path={[RouteTemplates.schedule]}>
          <PrivateMenuRoute key="schedule" available={isScheduleAvailable}>
            <Schedule />
          </PrivateMenuRoute>
        </Route>
        <Route
          path={[
            RouteTemplates.myLearningTracksAll,
            RouteTemplates.myLearningTracksInProgress,
            RouteTemplates.myLearningTracksCompleted,
            RouteTemplates.myLearningTracksAvailable,
            RouteTemplates.myLearningTrack,
          ]}
        >
          <PrivateMenuRoute key="learningTracks" available={isMyLearningTracksAvailable}>
            <LearningTracksRouter />
          </PrivateMenuRoute>
        </Route>
        <Route path={[RouteTemplates.dashboard]}>
          <PrivateMenuRoute key="dashboard" available={isDashboardAvailable}>
            <Dashboard />
          </PrivateMenuRoute>
        </Route>
        <Route path={[RouteTemplates.certificates]}>
          <Certificates />
        </Route>
        <Redirect to={redirectPath} />
      </Switch>
    </Suspense>
  );
};

export default observer(MainContent);
