/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["self"] }] */
import { cast, flow, types } from 'mobx-state-tree';
import { myTeamApi } from 'api';
import { isTalentRocksApp } from 'env';
import { GroupUserStatus } from '_constants';
import { TAttendeesResponse } from 'types/myTeamApiTypes';
import MAttendee from './MAttendee';
import PaginationContext from './MPaginationContext';

const MAttendees = types
  .model({
    attendees: types.maybeNull(types.array(MAttendee)),
    context: types.optional(PaginationContext, {}),
    groupId: types.maybeNull(types.number),
    filterByAssignments: types.optional(
      types.enumeration<GroupUserStatus>('FilterByAssignments', Object.values(GroupUserStatus)),
      GroupUserStatus.ALL,
    ),
    isFetching: false,
  })
  .actions((self) => ({
    fetchAttendees: flow(function* getAttendees(groupId: number, filterByAssignments: GroupUserStatus) {
      self.isFetching = true;
      const { data, hasError }: TAttendeesResponse = isTalentRocksApp
        ? yield myTeamApi.getAttendees(groupId, 1, filterByAssignments)
        : yield myTeamApi.getMobileAttendees(groupId, 1, filterByAssignments);
      if (!!data && !hasError) {
        self.attendees = cast(data.items);
        self.context = data.context;
        self.groupId = groupId;
        self.filterByAssignments = filterByAssignments;
      }
      self.isFetching = false;
      return data;
    }),
    fetchMoreAttendees: flow(function* fetchMoreAttendees() {
      if (!self.groupId || !self.filterByAssignments) return null;
      self.isFetching = true;
      const { data, hasError }: TAttendeesResponse = isTalentRocksApp
        ? yield myTeamApi.getAttendees(self.groupId, self.context.page + 1, self.filterByAssignments)
        : yield myTeamApi.getMobileAttendees(self.groupId, self.context.page + 1, self.filterByAssignments);
      if (!!data && !hasError) {
        self.attendees = cast(self.attendees?.concat(data.items));
        self.context = data.context;
      }
      self.isFetching = false;
      return null;
    }),
  }));

export default MAttendees;
