import React from 'react';
import { useIntl } from 'react-intl';
import { LabelWrapper, StyledUserIcon, AdditionalText } from './CardLabel.styles';
import LabelType from './constants';
import messages from './messages';

interface ICardLabelProps {
  labelType: LabelType;
  additionalText?: string;
}

const CardLabel: React.FC<ICardLabelProps> = ({ labelType, additionalText }) => {
  const intl = useIntl();

  const labelItems = {
    [LabelType.NEW]: {
      content: intl.formatMessage(messages.newText),
    },
    [LabelType.ADMIN]: {
      content: (
        <>
          <StyledUserIcon />
          {intl.formatMessage(messages.adminText)}
        </>
      ),
    },
    [LabelType.EXPIRED]: {
      content: intl.formatMessage(messages.expiredText),
    },
    [LabelType.DEADLINE]: {
      content: intl.formatMessage(messages.deadlineText),
    },
    [LabelType.RELATIVE_DEADLINE]: {
      content: intl.formatMessage(messages.relativeDeadlineText),
    },
    [LabelType.PASSED]: {
      content: intl.formatMessage(messages.passedText),
    },
  };

  return (
    <LabelWrapper labelType={labelType}>
      {labelItems[labelType].content}
      {additionalText && <AdditionalText labelType={labelType}>{additionalText}</AdditionalText>}
    </LabelWrapper>
  );
};

export default CardLabel;
