import { LOCALIZATION_CODES_MAP } from '_constants';
import { UIStore } from 'store';

const convertISOToDayMonth = (isoDate: string): string =>
  // TODO: При добавлении новых локализаций, например, казахской, подключить полифилл `@formatjs/intl-datetimeformat`
  // и соответствующие файлы локализации, так как в браузерах может не быть локализаций на эти языки
  new Intl.DateTimeFormat(LOCALIZATION_CODES_MAP[UIStore.locale], {
    day: 'numeric',
    month: 'short',
  }).format(new Date(isoDate.split('T')[0]));

export default convertISOToDayMonth;
