import { defineMessages } from 'react-intl';

export default defineMessages({
  newText: {
    id: 'components.cardLabel.newText',
    defaultMessage: 'New',
  },
  adminText: {
    id: 'components.cardLabel.adminText',
    defaultMessage: 'Admin',
  },
  expiredText: {
    id: 'components.cardLabel.expiredText',
    defaultMessage: 'Просрочено',
  },
  deadlineText: {
    id: 'components.cardLabel.deadlineText',
    defaultMessage: 'Срок: по ',
  },
  relativeDeadlineText: {
    id: 'components.cardLabel.relativeDeadlineText',
    defaultMessage: 'Срок: ',
  },
  passedText: {
    id: 'components.cardLabel.passedText',
    defaultMessage: 'Сдано: ',
  },
});
