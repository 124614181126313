import { types } from 'mobx-state-tree';

const MGroupDetails = types.model('MGroup', {
  id: types.number,
  name: types.string,
  attendeesCount: types.number,
  activeAttendeesCountInLast7Days: types.number,
  activeAttendeesCountInLast30Days: types.number,
});

export default MGroupDetails;
