import styled from 'styled-components';
import { ReactComponent as UserIcon } from 'assets/icons/user.svg';
import LabelType from './constants';

export const LabelWrapper = styled.div<{ labelType: LabelType }>`
  display: flex;
  align-items: center;
  height: 24px;
  padding: 6px 8px;
  border-radius: 8px;
  font-weight: 500;
  font-size: 12px;
  font-family: 'Roboto', sans-serif;
  line-height: 12px;
  white-space: pre-wrap;
  background-color: ${({ labelType, theme }) => {
    switch (labelType) {
      case LabelType.NEW:
        return theme.palette.notify;
      case LabelType.ADMIN:
        return theme.palette.primary.main;
      case LabelType.EXPIRED:
      case LabelType.PASSED:
        return theme.palette.error.error_15;
      case LabelType.DEADLINE:
      case LabelType.RELATIVE_DEADLINE:
        return theme.palette.blueGrey.blueGrey_15;
      default:
        return 'transparent';
    }
  }};
  color: ${({ labelType, theme }) => {
    switch (labelType) {
      case LabelType.EXPIRED:
      case LabelType.PASSED:
        return theme.palette.error.primary;
      case LabelType.DEADLINE:
      case LabelType.RELATIVE_DEADLINE:
        return theme.palette.blueGrey.main;
      default:
        return theme.palette.white.primary;
    }
  }};

  svg {
    margin-right: 6px;
    fill: ${({ theme }) => theme.palette.white.primary};
  }
`;

export const StyledUserIcon = styled(UserIcon)`
  margin-right: 6px;
`;

export const AdditionalText = styled.span<{ labelType: LabelType }>`
  color: ${({ labelType, theme }) => {
    switch (labelType) {
      case LabelType.PASSED:
        return theme.palette.error.primary;
      default:
        return theme.palette.blueGrey.main;
    }
  }};
`;
