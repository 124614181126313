import { types } from 'mobx-state-tree';
import { RelativeDeadlineUnits } from '_constants';

const MCourseInfo = types.model({
  id: types.number,
  name: types.string,
  coverThumbnailUrl: types.maybeNull(types.string),
  assignedCount: types.number,
  completedCount: types.number,
  expiredCount: types.maybeNull(types.number),
  hasExpiredAttendees: types.maybeNull(types.boolean),
  fixedDeadlineDate: types.maybeNull(types.string),
  relativeDeadlineUnits: types.maybeNull(
    types.enumeration<RelativeDeadlineUnits>('RelativeDeadlineUnits', Object.values(RelativeDeadlineUnits)),
  ),
  relativeDeadlineValue: types.maybeNull(types.number),
});

export default MCourseInfo;
