import styled from 'styled-components';
import { Card } from 'components';
import { Grid } from '_constants';
import { ReactComponent as ChevronRight } from 'assets/icons/chevron-right.svg';
import { ReactComponent as DownloadIcon } from 'assets/icons/download-arrow.svg';
import CertificateIcon from 'assets/icons/certificate.png';

export const Container = styled(Card)`
  display: flex;
  align-items: center;
  gap: 16px;
  padding: 20px;
  margin: 0;
  cursor: pointer;
`;

export const ImageContainer = styled.div`
  display: none;
`;

export const Icon = styled.div`
  flex-shrink: 0;
  width: 82px;
  height: 82px;
  background-image: url(${CertificateIcon});
  background-size: contain;

  @media (max-width: ${Grid.MD}px) {
    display: none;
  }
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const Title = styled.span`
  color: ${({ theme }) => theme.palette.dark.main};
  font-size: 18px;
  font-weight: 500;
  line-height: 1.3;
  margin-bottom: 4px;
`;

export const Status = styled.span`
  color: ${({ theme }) => theme.palette.dark.dark_68};
  font-size: 14px;
  font-weight: 400;
  line-height: 1.7;
  margin-bottom: 8px;
`;

export const ButtonText = styled.span`
  border-bottom: 1px dashed;
  border-color: ${({ theme: { palette } }) => palette.violet.violet_20};
  color: ${({ theme: { palette } }) => palette.violet.main};
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4;
`;

export const DownloadButton = styled.button`
  width: max-content;
  border: none;
  background: none;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 0;
  margin: 0;
  cursor: pointer;

  &:hover ${ButtonText} {
    border-color: transparent;
  }
`;

export const DownloadButtonIcon = styled(DownloadIcon)`
  width: 20px;
  height: 20px;
`;

export const Chevron = styled(ChevronRight)`
  width: 32px;
  height: 32px;
`;
