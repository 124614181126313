/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["self"] }] */
import { cast, flow, types } from 'mobx-state-tree';
import { myTeamApi } from 'api';
import { isTalentRocksApp } from 'env';
import { TCourseAttendeeResponse } from 'types/myTeamApiTypes';
import { CourseUserStatus } from '_constants';
import MCourseAttendee from './MCourseAttendee';
import MPaginationContext from './MPaginationContext';

const MCourseAttendees = types
  .model({
    attendees: types.maybeNull(types.array(MCourseAttendee)),
    context: types.optional(MPaginationContext, {}),
    courseId: types.maybeNull(types.number),
    groupId: types.maybeNull(types.number),
    trackId: types.maybe(types.string),
    userStatus: types.optional(
      types.enumeration<CourseUserStatus>('Rage', Object.values(CourseUserStatus)),
      CourseUserStatus.ASSIGNED,
    ),
    isFetching: false,
  })
  .actions((self) => ({
    fetchAttendees: flow(function* getAttendees(
      courseId: number,
      groupId: number,
      userStatus: CourseUserStatus,
      trackId?: string,
    ) {
      self.isFetching = true;
      const getCourseAttendees = isTalentRocksApp
        ? myTeamApi.courseApi.getCourseAttendees
        : myTeamApi.courseApi.getMobileCourseAttendees;
      const { data, hasError }: TCourseAttendeeResponse = yield getCourseAttendees(
        courseId,
        groupId,
        userStatus,
        trackId,
      );
      if (!!data && !hasError) {
        self.attendees = cast(data.items);
        self.context = data.context;
        self.userStatus = userStatus;
        self.courseId = courseId;
        self.groupId = groupId;
        self.trackId = trackId;
      }
      self.isFetching = false;
      return data;
    }),
    fetchMoreAttendees: flow(function* fetchMoreAttendees() {
      if (!self.courseId || !self.groupId || !self.userStatus) return null;
      self.isFetching = true;
      const getCourseAttendees = isTalentRocksApp
        ? myTeamApi.courseApi.getCourseAttendees
        : myTeamApi.courseApi.getMobileCourseAttendees;
      const { data, hasError }: TCourseAttendeeResponse = yield getCourseAttendees(
        self.courseId,
        self.groupId,
        self.userStatus,
        self.trackId,
        self.context.page + 1,
      );
      if (!!data && !hasError) {
        self.attendees = cast(self.attendees?.concat(data.items));
        self.context = data.context;
      }
      self.isFetching = false;
      return data;
    }),
  }));

export default MCourseAttendees;
