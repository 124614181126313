/* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["self"] }] */
import { myTeamApi } from 'api';
import { isTalentRocksApp } from 'env';
import { cast, flow, getRoot, types } from 'mobx-state-tree';
import { TStore } from 'types';
import { TMyTeamCourseResponse } from 'types/myTeamApiTypes';
import { CourseUserStatus } from '_constants';
import MCourse from './MCourse';
import MCourseInfo from './MCourseInfo';
import MCourseAttendees from './MCourseAttendees';

const MTrack = types
  .model({
    id: types.identifier,
    coursesInfo: types.maybeNull(types.array(MCourseInfo)),
    courses: types.map(MCourse),
    expiredCourseAttendees: types.map(
      types.model({
        id: types.identifier,
        data: MCourseAttendees,
      }),
    ),
  })
  .views((self) => ({
    get myTeamUIStore() {
      return getRoot<TStore>(self).UIStore.myTeam;
    },
    get coursesWithExpiredAttendees() {
      return self.coursesInfo?.filter((course) => course.hasExpiredAttendees);
    },
    get coursesExpiredAttendeesSum() {
      return self.coursesInfo?.reduce(
        (sum, course) => sum + (course.hasExpiredAttendees ? course.expiredCount || 0 : 0),
        0,
      );
    },
  }))
  .actions((self) => ({
    setCourseId: (id: number) => {
      if (!self.courses.get(String(id))) {
        self.courses.put(MCourse.create({ courseId: id }));
      }
    },
    fetchAssignCourses: flow(function* fetchAssignCourses(groupId: number, trackId: string) {
      self.myTeamUIStore.setIsFetching(true);
      const getTrackSteps = isTalentRocksApp
        ? myTeamApi.tracksApi.getTrackSteps
        : myTeamApi.tracksApi.getMobileTrackSteps;
      const { data, hasError }: TMyTeamCourseResponse = yield getTrackSteps(trackId, groupId);
      if (data) {
        self.coursesInfo = cast(data);
      }
      self.myTeamUIStore.setIsFetching(false);
      return !hasError;
    }),
    fetchExpiredAttendeesForCourses: flow(function* fetchExpiredAttendeesForCourses(groupId: number) {
      self.myTeamUIStore.setIsFetching(true);
      const getAttendees = async (courseId: number) => {
        self.expiredCourseAttendees.put({ id: String(courseId), data: MCourseAttendees.create() });
        return self.expiredCourseAttendees
          .get(String(courseId))
          ?.data.fetchAttendees(courseId, groupId, CourseUserStatus.EXPIRED, self.id);
      };
      let responses: boolean[] = [];
      if (self.coursesWithExpiredAttendees) {
        responses = yield Promise.all(self.coursesWithExpiredAttendees.map((course) => getAttendees(course.id)));
      }
      self.myTeamUIStore.setIsFetching(false);
      return responses.every((r) => r);
    }),
  }));

export default MTrack;
