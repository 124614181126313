import { ResponsePromise } from 'ky';
import { Input, Options, HttpMethod } from 'ky/distribution/types/options';
import { KyInstance as IKy } from 'ky/distribution/types/ky';
import { isGamificationApp, isMyTeamApp } from 'env';
import webViewStore from 'store/WebView';
import store from 'store';
import { TResponded } from 'types';
import { USER_METRICS_URL } from './constants';

interface TKyMethod {
  (url: Input, options?: Options): ResponsePromise;
}

async function request<T>(method: TKyMethod, url: Input, options?: Options): Promise<TResponded<T>> {
  let response: Response | null = null;
  let data: T | null = null;
  let hasError = false;

  try {
    response = await method(url, { ...options });
    const contentTypeHeader = response.headers.get('content-type');
    if (contentTypeHeader?.includes('application/json')) {
      data = await response.json();
      hasError = !response.ok;
    }
  } catch (error: unknown) {
    hasError = true;

    if ((error as Error).name !== 'AbortError' && url !== USER_METRICS_URL) {
      store.UIStore.notifications.setNotification({
        type: 'error',
        closable: true,
      });
      if (isGamificationApp || isMyTeamApp) {
        webViewStore.showNetworkErrorPlaceholder();
      }
    }
  }

  return {
    response,
    hasError,
    data,
  };
}

export const makeRequest =
  (attachedApi: IKy) =>
  <T>(method: HttpMethod, url: Input, options?: Options): Promise<TResponded<T>> =>
    request<T>(attachedApi[method], url, options);

export default request;
