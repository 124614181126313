import React, { useMemo } from 'react';
import { observer } from 'mobx-react';
import { useIntl } from 'react-intl';
import { TCourseInfo, TDashboardCourseInfo } from 'types';
import { CourseStatuses, DashboardCourseTrackStatuses } from 'models';
import { CardLabel, Image } from 'components';
import { ProgressBarWidth, ParticipationDeadlineStatus } from '_constants';
import store from 'store';
import { LabelType } from 'components/CardLabel';
import { convertISOToDayMonth } from 'utils';
import {
  Body,
  CompletedLabel,
  CourseCardImageOverlay,
  Header,
  HeaderLeft,
  Info,
  LabelsLine,
  Percent,
  StagesWrapper,
  StyledCheckmarkIcon,
  StyledProgress,
  Title,
  Wrapper,
} from './CourseCard.styles';
import messages from './messages';

interface ICourseCard {
  isNew: boolean;
  isInDashboard?: boolean;
}

const CourseCard: React.FC<(ICourseCard & TCourseInfo) | (ICourseCard & TDashboardCourseInfo)> = ({
  name,
  stagesCount,
  stagesPassed,
  status,
  viewInAdminMode,
  coverThumbnailUrl,
  coverUrl,
  isNew,
  isInDashboard,
  deadlineDate,
  participationDeadlineStatus: deadlineStatus,
}) => {
  const intl = useIntl();
  const percent = stagesCount && stagesPassed && Math.round((stagesPassed / stagesCount) * 100);
  const cover = useMemo(() => coverThumbnailUrl || coverUrl, [coverUrl, coverThumbnailUrl]);

  return (
    <Wrapper isInDashboard={isInDashboard}>
      {cover && (
        <CourseCardImageOverlay>
          <Image original={cover} thumbnail={coverThumbnailUrl || ''} />
        </CourseCardImageOverlay>
      )}
      <Header>
        <HeaderLeft>
          {deadlineDate && status !== CourseStatuses.COMPLETED && status !== DashboardCourseTrackStatuses.COMPLETED && (
            <LabelsLine>
              {deadlineStatus === ParticipationDeadlineStatus.HAS_BEEN_REACHED && (
                <CardLabel labelType={LabelType.EXPIRED} />
              )}
              <CardLabel labelType={LabelType.DEADLINE} additionalText={convertISOToDayMonth(deadlineDate)} />
            </LabelsLine>
          )}
          {viewInAdminMode && <CardLabel labelType={LabelType.ADMIN} />}
        </HeaderLeft>
        {isNew && <CardLabel labelType={LabelType.NEW} />}
      </Header>
      <Body>
        <Title ellipsis={{ rows: 2 }}>{name}</Title>
        <Info isCompleted={status === CourseStatuses.COMPLETED || status === DashboardCourseTrackStatuses.COMPLETED}>
          <StagesWrapper>
            {(status === CourseStatuses.NOT_STARTED || status === DashboardCourseTrackStatuses.NOT_STARTED) &&
              intl.formatMessage(messages.stagesCount, { count: stagesCount })}
            {(status === CourseStatuses.COMPLETED || status === DashboardCourseTrackStatuses.COMPLETED) && (
              <>
                <StyledCheckmarkIcon />
                <CompletedLabel>{intl.formatMessage(messages.completed)}</CompletedLabel>
              </>
            )}
            {(status === CourseStatuses.IN_PROGRESS || status === DashboardCourseTrackStatuses.IN_PROGRESS) &&
              intl.formatMessage(messages.completedOf, { stagesPassed, stagesCount })}
          </StagesWrapper>
          <Percent isSuccess={status === CourseStatuses.COMPLETED || status === DashboardCourseTrackStatuses.COMPLETED}>
            {percent || 0}%
          </Percent>
        </Info>
        {status !== CourseStatuses.COMPLETED && status !== DashboardCourseTrackStatuses.COMPLETED && (
          <StyledProgress
            percent={Math.max(percent, ProgressBarWidth.MD)}
            showInfo={false}
            strokeWidth={4}
            trailColor={store.UIStore.theme.palette.white.primary}
            strokeColor={store.UIStore.theme.palette.success.primary}
          />
        )}
      </Body>
    </Wrapper>
  );
};

export default observer(CourseCard);
