enum LabelType {
  NEW = 'new',
  ADMIN = 'admin',
  EXPIRED = 'expired',
  DEADLINE = 'deadline',
  RELATIVE_DEADLINE = 'relativeDeadline',
  PASSED = 'passed',
}

export default LabelType;
