import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import { Image } from 'antd';
import { UIStore } from 'store';
import downloadFile from 'utils/downloadFile';
import {
  Container,
  ImageContainer,
  Icon,
  Content,
  Title,
  Status,
  DownloadButton,
  ButtonText,
  DownloadButtonIcon,
  Chevron,
} from './Certificate.styles';
import messages from './messages';

interface ICertificate {
  serialNumber: string;
  downloadUrl: string;
}

const Certificate: React.FC<ICertificate> = ({ serialNumber, downloadUrl }) => {
  const intl = useIntl();
  const { palette } = UIStore.theme;
  const [show, setShow] = useState(false);

  const download = async (fileName: string, url: string) => {
    if (url) {
      await downloadFile({ fileName, downloadSrc: url });
    }
  };

  const handleClickPreview = () => {
    setShow(true);
  };

  return (
    <Container onClick={handleClickPreview}>
      <ImageContainer>
        <Image
          src={downloadUrl}
          preview={{
            visible: show,
            onVisibleChange: () => setShow(false),
            style: { backgroundColor: palette.mask.slightlyDark },
          }}
        />
      </ImageContainer>
      <Icon />
      <Content>
        <Title>{intl.formatMessage(messages.title)}</Title>
        <Status>{intl.formatMessage(messages.status)}</Status>
        <DownloadButton
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
            e.preventDefault();
            e.stopPropagation();
            download(`certificate_${serialNumber}`, downloadUrl);
          }}
          type="button"
        >
          <DownloadButtonIcon />
          <ButtonText>{intl.formatMessage(messages.downloadButton)}</ButtonText>
        </DownloadButton>
      </Content>
      <Chevron />
    </Container>
  );
};

export default Certificate;
