import React, { useLayoutEffect, useCallback, lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import { observer } from 'mobx-react';
import { DomainStore } from 'store';
import { coursesApi } from 'api';
import { useAppNavigator } from 'utils';
import { RouteTemplates } from '_constants';
import StageRouter from 'views/Stage/StageRouter';

const Course = lazy(() => import('views/Course'));

const CourseRouter: React.FC = () => {
  const {
    params: { courseId, trackId, trackStepId },
    isMyCoursesPath,
  } = useAppNavigator();

  const { fetchRequiredData } = DomainStore.course;
  const { fetchCourses } = DomainStore.courses;

  const init = useCallback(
    async (id: number, learningTrackId: string, learningTrackStepId: string) => {
      const resolved = await fetchRequiredData(id, learningTrackId, learningTrackStepId);
      if (!isMyCoursesPath) {
        await fetchCourses();
      }
      if (resolved) {
        await coursesApi.courseOpened(id, learningTrackId);
      }
    },
    [fetchRequiredData, fetchCourses, isMyCoursesPath],
  );

  useLayoutEffect(() => {
    if (courseId) {
      init(courseId, trackId, trackStepId);
    }
  }, [courseId, init, trackId, trackStepId]);

  return (
    <Switch>
      <Route
        path={[RouteTemplates.stage, RouteTemplates.menuCourseStage, RouteTemplates.myLearningTrackStage]}
        component={StageRouter}
      />
      <Route
        path={[RouteTemplates.course, RouteTemplates.menuCourse, RouteTemplates.myLearningTrackCourse]}
        component={Course}
      />
    </Switch>
  );
};

export default observer(CourseRouter);
