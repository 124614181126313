import { api } from 'api';
import { TResponded } from 'types';
import { TCertificateResponse, TCertificatesDetailsResponse } from 'types/certificatesTypes';

const getCertificate = (certificateId: string): Promise<TResponded<TCertificateResponse>> =>
  api.privateRequest('get', `api/web/tlrocks/attendees/certificates/${certificateId}`);

const getCertificates = (): Promise<TCertificatesDetailsResponse> =>
  api.privateRequest('post', `api/web/tlrocks/attendees/certificates`);

export default {
  getCertificate,
  getCertificates,
};
